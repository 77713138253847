import React, { useEffect, useState } from 'react';
import { Card as BsCard } from 'react-bootstrap';

import { Link } from 'gatsby';

const Card = ({
  imgSrc,
  title,
  description,
  path,
  categories,
  activeFilter,
}) => {
  const [isCardVisible, setIsCardVisible] = useState(true);

  useEffect(() => {
    if (activeFilter !== 'All') {
      setIsCardVisible(categories.some(item => item.name === activeFilter));
    } else {
      setIsCardVisible(true);
    }
  }, [activeFilter]);

  return (
    <BsCard
      className={`product-card ${isCardVisible ? '' : 'product-card--hidden'}`}
    >
      <BsCard.Img variant="top" src={`images/${imgSrc}`} />
      <Link to={`/project/${path}`}>
        <div className="product-card__overlay">
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      </Link>
    </BsCard>
  );
};

export default Card;
