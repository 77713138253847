import React from 'react';

import Hero from '../components/Home/Hero';
import Cards from '../components/Home/Cards';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import FilterProvider from '../components/Home/FilterContext';

const Home = ({ pageContext }) => {
  return (
    <Layout>
      <SEO title={pageContext.title} />
      <Hero />
      <Cards data={pageContext.data} />
    </Layout>
  );
};

const HomeWithFilters = ({ pageContext }) => (
  <FilterProvider>
    <Home pageContext={pageContext} />
  </FilterProvider>
);

export default HomeWithFilters;
