import React, { useContext } from 'react';
import { Container } from 'react-bootstrap';

import { FilterContext } from './FilterContext';
import Card from './Card';

const Cards = ({ data }) => {
  const { activeFilter } = useContext(FilterContext);

  return (
    <Container fluid="lg" className="cards-wrap">
      <div className="card-columns">
        {data.map(item => (
          <Card
            imgSrc={item.img}
            title={item.name}
            description={item.shortDescription}
            path={item.path}
            categories={item.categories}
            activeFilter={activeFilter}
            key={item.name}
          />
        ))}
      </div>
    </Container>
  );
};

export default Cards;
