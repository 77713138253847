import React, { createContext, useState } from 'react';

export const FilterContext = createContext({});

const FilterProvider = ({ children }) => {
  const [activeFilter, setActiveFilter] = useState('All');

  const activateFilter = state => {
    setActiveFilter(state);
  };

  const value = {
    activeFilter,
    activateFilter,
  };

  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  );
};

export default FilterProvider;
