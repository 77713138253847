import React from 'react';

import NGLogo from '../../assets/images/omega-ng-inverse.svg';
import Filters from './Filters';

const Hero = () => (
  <div className="hero">
    <div className="hero__img"></div>
    <a href="https://www.netgroup.com/et/" className="hero__logo">
      <img src={NGLogo} alt="Net Group logo" />
    </a>
    <h1 className="hero__title">
      team.<span className="font-weight-bold">Omega</span>
    </h1>
    <p className="hero__text">
      Sit back, relax and enjoy the show - our work we have built for the best
      customers in the world. We would love to make difficult things simple for
      you too.
      <br /> <a href="mailto:omega@netgroup.com"> omega@netgroup.com</a>
    </p>
    <Filters />
  </div>
);

export default Hero;
