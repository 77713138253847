import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { FilterContext } from './FilterContext';

const Filters = () => {
  const { activeFilter, activateFilter } = useContext(FilterContext);

  return (
    <div className="filters">
      <Button
        className={`filters__btn${
          activeFilter === 'All' ? ' filters__btn--active' : ''
        }`}
        onClick={() => activateFilter('All')}
      >
        All
      </Button>
      <Button
        className={`filters__btn${
          activeFilter === 'UX/UI design' ? ' filters__btn--active' : ''
        }`}
        onClick={() => activateFilter('UX/UI design')}
      >
        UX/UI design
      </Button>
      <Button
        className={`filters__btn${
          activeFilter === 'Digital processes' ? ' filters__btn--active' : ''
        }`}
        onClick={() => activateFilter('Digital processes')}
      >
        Digital processes
      </Button>
      <Button
        className={`filters__btn${
          activeFilter === 'Online tools' ? ' filters__btn--active' : ''
        }`}
        onClick={() => activateFilter('Online tools')}
      >
        Online tools
      </Button>
      <Button
        className={`filters__btn${
          activeFilter === 'Service design' ? ' filters__btn--active' : ''
        }`}
        onClick={() => activateFilter('Service design')}
      >
        Service design
      </Button>
      <Button
        className={`filters__btn${
          activeFilter === 'Data vizualisation' ? ' filters__btn--active' : ''
        }`}
        onClick={() => activateFilter('Data vizualisation')}
      >
        Data vizualisation
      </Button>
    </div>
  );
};

export default Filters;
